import m from 'mithril'
import * as Page from 'front/pages'
import isMobile from 'lib/detect-mobile'

const root = document.body
window.isMobile = isMobile()

const managerRoutes = {
    '/': Page.DashboardPage,
}

export function render() {
    m.route(root, '/', managerRoutes)
}
