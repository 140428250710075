import m from 'mithril'
import classNames from 'classnames'
import {
    Dialog
} from 'components'
import QuestionDialog from './dialogs/question'

class DashboardPage {
    constructor() {
        this.categories = [
            {
                title: "我是晚期非小細胞肺癌病人", subtitle: "具有EGFR基因變異", color: "#AFD8DD", type: 0,
                options: [{ name: "Avastin 癌思停", range: "每3週一次", perml: 100, useml: 7.5, anotheruseml: 15, isWeight: true, freq: 3, isCombile: false },
                { name: "Cyramza 新銳擇", range: "每2週一次", perml: 100, useml: 10, isWeight: true, freq: 3, isCombile: false }],
            },
            {
                title: "我是晚期非小細胞肺癌的病人", subtitle: "但沒有EGFR或ALK基因變異", color: "#89D4DD", type: 1,
                options: [{ name: "Tecentriq 癌自禦 / 每3週一次", perml: 1200, useml: 1200, isWeight: false, freq: 3, isCombile: true },
                { name: "Keytruda 吉舒達 / 每3週一次", perml: 100, useml: 200, isWeight: false, freq: 3, isCombile: false },
                { name: "Opdivo 保疾伏 / 每2週一次", perml: 20, anotherperml: 100, useml: 3, isWeight: true, freq: 3, isCombile: false ,changeOrder:true },
                    { name: "Imfinzi 抑癌寧 / 每2週一次", perml: 120, anotherperml: 500, useml: 10, isWeight: true, freq: 3, isCombile: false, changeOrder: true }],
            },
            {
                title: "我是", subtitle: "擴散期小細胞肺癌病人", color: "#77C0C9", type: 2,
                options: [{ name: "Tecentriq 癌自禦", range: "每3週一次", perml: 1200, useml: 1200, isWeight: false, freq: 3, isCombile: false },
                { name: "Imfinzi 抑癌寧", range: "每3週一次", perml: 500, useml: 1500, isWeight: false, freq: 3, isCombile: false }],
            },
        ]

    }
    oncreate(vnode) {

    }

    view() {
        return [
            m(".row.no-gutters.w-100", [
                m(".col-12.d-flex.align-items-end", {
                    style: {
                        height: "17.5vh",
                        background: "#89B1CE"
                    }
                }, [
                    m(".row.no-gutters.w-100", [
                        m(".col-12.text-center", [
                            m("span.text-light.font-weight-bold", {
                                style: {
                                    fontSize: "1.4rem"
                                }
                            }, "肺癌病人自費藥物試算"),
                        ]),
                        m(".col-12.pb-3.pt-1.text-center", [
                            m("span.text-light.font-weight-lighter", "我是下列哪種病人?"),
                        ]),
                    ])
                ])
            ]),
            m(".row.no-gutters.w-100", [
                m(".col-12", {
                    style: {
                        height: "68vh",
                        background: "#FFFFFF"
                    }
                }, [
                    m(".row.no-gutters.w-100.py-4.px-4.pt-md-5", [
                        m(".container", [
                            m(".row.no-gutters.d-flex.justify-content-center", [

                                this.categories.map((_category, index) => {
                                    return [
                                        m(".col-12.col-md-4.px-2.py-2", {
                                            onclick: (e) => {
                                                e.preventDefault()
                                                Dialog.show(QuestionDialog, {
                                                    attrs: {
                                                        model: _category
                                                    },
                                                })
                                            }
                                        }, [
                                            m(".py-4", {
                                                style: {
                                                    background: _category.color,
                                                    borderRadius: "15px",
                                                    cursor: "pointer",

                                                }
                                            }, [
                                                m(".row.no-gutters.w-100", [
                                                    m(".col-12.text-center", [
                                                        m("span.text-light.font-weight-bold", {
                                                            style: {
                                                                fontSize: "1.25rem"
                                                            }
                                                        }, _category.title),
                                                    ]),
                                                    m(".col-12.text-center", [
                                                        m("span.text-light.font-weight-bold", {
                                                            style: {
                                                                fontSize: "1.25rem"
                                                            }
                                                        }, _category.subtitle),
                                                    ]),
                                                ])
                                            ])
                                        ]),
                                    ]
                                }),
                                m(".col-12.px-2.pt-4.col-md-4.pt-md-5", [
                                    m(".py-2", {
                                        style: {
                                            background: "#FFFFFF",
                                            border: "1px #89B1CE solid",
                                            borderRadius: "10px",
                                            cursor: "pointer"
                                        }
                                    }, [
                                        m(".row.no-gutters.w-100", [
                                            m(".col-12.text-center", [
                                                m("span", {
                                                    style: {
                                                        fontSize: "1.25rem",
                                                        color: "#89B1CE",
                                                    }
                                                }, "請選擇您的類型作計算"),
                                            ]),
                                        ])
                                    ])
                                ]),
                            ]),
                        ]),
                    ])
                ])
            ]),
            m(".row.no-gutters.w-100", [
                m(".col-12.d-flex.align-items-end.justify-content-center", {
                    style: {
                        height: "14.5vh",
                        maxHeight: "14.5vh",
                        background: "#FFFFFF",
                        boxShadow: "0px -2px 8px #00000029"
                    }
                }, [
                    m("img.img-fluid", {
                        src: "./img/logo.png",
                        style:{
                            height: "14.5vh",
                            maxHeight: "14.5vh",
                        }
                    }),
                ])
            ]),
            m(Dialog),
        ]
    }
}

export default DashboardPage