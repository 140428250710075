import m from 'mithril'
import {
    Dialog,
} from 'components'
import { relativeTimeThreshold } from 'moment'


class EventDialog {
    constructor(vnode) {
        this.model = vnode.attrs.model
        this.select_medicine = -1
        this.ranges = [{ name: "一次療程", value: 1 }, { name: "半年療程 / 8次", value: 26 }, { name: "一年療程 / 17次", value: 52 }]
        this.select_range = null
        this.select_range2 = null
        this.weight = null
        this.price = null
        this.weight2 = null
        this.price2 = null
        this.total = 0
        this.total2 = 0
        this.perml = null

        this.useml = null
        this.useml2 = null
        this.combile_select = false

    }
    close(event, value) {
        setTimeout(() => {
            Dialog.close(event)
            m.redraw()
        }, 200)
    }
    calculation() {
        if (this.select_range == 1) {
            this.times = 1
        } else {
            this.times = Math.floor(this.select_range / this.model.options[this.select_medicine].freq)
        }
        if (this.model.options[this.select_medicine].isWeight) {
            this.total = Math.ceil(this.weight * Math.ceil(this.useml) * this.times / this.perml) * this.price
        } else {
            this.total =
                Math.ceil((this.useml * this.times)
                    / this.perml) * this.price

        }

    }

    view(vnode) {
        console.log((this.model.options[this.select_medicine])?this.model.options[this.select_medicine].changeOrder:"sd")
        return m('.modal-dialog.m-0.postion-relative', {
            style: {
                height: "100vh",
                maxHeight: "100vh",
                minWidth: "100%",
                maxWidth: "100%",
            }
        }, [
            m(".modal-content.border-0.rounded-0", {
                style: {
                    height: "100vh"
                }
            }, [
                m(".row.no-gutters.w-100", [
                    m(".col-12.d-flex.align-items-center", {
                        style: {
                            height: "17.5vh",
                            background: this.model.color
                        }
                    }, [
                        m(".container", [
                            m(".row.no-gutters.w-100.pl-4", [
                                m(".col-12", [
                                    m("span.text-light.font-weight-bold", {
                                        style: {
                                            fontSize: "1.4rem"
                                        }
                                    }, this.model.title),
                                ]),
                                (".col-12", [
                                    m("span.text-light.font-weight-bold", {
                                        style: {
                                            fontSize: "1.4rem"
                                        }
                                    }, this.model.subtitle),
                                ]),
                            ])
                        ])

                    ])
                ]),
                m(".row.no-gutters.w-100", [
                    m(".col-12", {
                        style: {
                            height: (this.combile_select) ? "118vh" : "73vh",
                            maxHeight: (this.combile_select) ? "118vh" : "73vh",
                            background: "#FFFFFF"
                        }
                    }, [
                        m(".row.no-gutters.w-100.pt-3.pb-1.px-4", [
                            m(".container", [
                                m(".row.no-gutters.w-100", [
                                    m(".col-12.d-flex.justify-content-between.pb-3", [
                                        m(".px-2.py-1", {
                                            style: {
                                                background: this.model.color
                                            }
                                        }, [
                                            m("span.text-light", {
                                                style: {
                                                    fontSize: "1rem"              
                                                }
                                            }, "自費用藥選項")
                                        ]),
                                        m(".px-2.py-1", {
                                            style: {
                                                background: this.model.color,
                                                cursor:"pointer"
                                            },
                                            onclick: (e) => {
                                                e.preventDefault()  
                                                this.close()
                                            }
                                        }, [
                                            m("span.text-light" , {
                                                style: {
                                                    fontSize: "1rem"
                                                }
                                            }, "回首頁")
                                        ]),
                                    ]),
                                    this.model.options.map((_option, index) => {
                                        return [
                                            m(".col-12.col-md-5.py-2", [
                                                m(".row.no-gutters.w-100", {
                                                    onclick: (e) => {
                                                        e.preventDefault()
                                                        if (this.select_medicine == index) {
                                                            this.select_medicine = -1
                                                            this.perml = null

                                                        } else {
                                                            this.select_medicine = index
                                                            if (this.model.options[this.select_medicine].anotherperml) {
                                                                this.perml = null
                                                            } else {
                                                                this.perml = this.model.options[this.select_medicine].perml
                                                            }
                                                            if (this.model.options[this.select_medicine].anotheruseml) {
                                                                this.useml = null
                                                            } else {
                                                                this.useml = this.model.options[this.select_medicine].useml
                                                            }
                                                        }
                                                        this.combile_select = false
                                                        this.weight = null
                                                        this.price = null
                                                        this.total = 0
                                                    }
                                                }, [
                                                    m(".col-1", {
                                                        style: {
                                                            border: "1px " + ((this.select_medicine == index) ? this.model.color : "#000000") + " solid",
                                                            background: (this.select_medicine == index) ? this.model.color : "#FFFFFF",
                                                            minWidth: "25px",
                                                            maxWidth: "25px",
                                                            minHeight: "25px",
                                                            maxHeight: "25px"
                                                        }
                                                    }),
                                                    m(".col-10.pl-2", [
                                                        m("span.font-weight-bold", {
                                                            style: {
                                                                color: "#707070",
                                                                fontSize: "1.2rem"
                                                            }
                                                        }, _option.name)
                                                    ])
                                                ])
                                            ])
                                        ]
                                    }),

                                    (this.select_medicine != -1) ? [
                                        m(".col-12.text-center.text-md-left.pt-2", [
                                            m("span.font-weight-bold", { style: { color: "#77C0C9" } }, this.model.options[this.select_medicine].name + ((this.model.options[this.select_medicine].range) ? " / " + this.model.options[this.select_medicine].range : "")),

                                        ])
                                    ] : ""
                                ]),
                            ]),
                        ]),
                        (this.select_medicine != -1) ? [
                            m(".row.no-gutters.w-100", {
                                style: {
                                    background: "#CDE1E3",
                                    height: (window.isMobile) ? ((this.combile_select) ? "105vh" : "91vh") : ((this.combile_select) ? "83vh" : "58vh"),
                                    overflow: "auto"
                                }
                            }, [
                                m(".px-4.py-3.w-100", {
                                    style: {
                                        height: (window.isMobile) ? "52vh" : "36vh"
                                    }
                                }, [
                                    m(".container", [
                                        m(".row.no-gutters.w-100", [
                                            (this.model.options[this.select_medicine].anotherperml) ? [
                                                m(`.col-12.col-md-2.pt-2.pt-md-4${(this.model.options[this.select_medicine].changeOrder) ? ".order-4" :".order-1"}`, [
                                                    m("span.font-weight-bold", {
                                                        style: {
                                                            color: "#707070",
                                                            fontSize: "0.9rem"
                                                        }
                                                    }, "施打劑量(次)"),
                                                    m("span.font-weight-bold.pl-4", this.model.options[this.select_medicine].useml),
                                                    m("span.font-weight-bold", (this.model.options[this.select_medicine].isWeight) ? "mg/kg" : "mg"),
                                                ]),
                                                m(`.col-12${(this.model.options[this.select_medicine].changeOrder) ? ".order-3" : ".order-3"}`),
                                                m(`.col-12.col-md-1.pt-md-4${(this.model.options[this.select_medicine].changeOrder) ? ".order-1" : ".order-2"}`, [
                                                    m("span.font-weight-bold", {
                                                        style: {
                                                            color: "#707070",
                                                            fontSize: "0.9rem"
                                                        }
                                                    }, "每瓶劑量"),
                                                ]),
                                                m(`.col-12.col-md-4.pt-md-4${(this.model.options[this.select_medicine].changeOrder) ? ".order-2" :".order-4"}`, [
                                                    m("select.form-control.border-0", {
                                                        onchange: (e) => {
                                                            e.preventDefault()
                                                            this.perml = e.target.value
                                                            if (this.price != null && this.select_range != null) {
                                                                if (this.model.options[this.select_medicine].isWeight && this.weight != null) {
                                                                    this.calculation()
                                                                } else {
                                                                    this.calculation()
                                                                }
                                                            }
                                                        },

                                                    }, [
                                                        m("option", "請選擇劑量"),
                                                        [this.model.options[this.select_medicine].perml, this.model.options[this.select_medicine].anotherperml].map((_value, index) => {
                                                            return [
                                                                m("option", {
                                                                    value: _value,
                                                                }, _value + " mg")
                                                            ]
                                                        })
                                                    ]),
                                                ]),
                                                m(".col-md-12.order-5")
                                            ] : [
                                                    m(".col-12.col-md-2.pt-md-4", [
                                                        m("span.font-weight-bold", {
                                                            style: {
                                                                color: "#707070",
                                                                fontSize: "0.9rem"
                                                            }
                                                        }, "每瓶劑量"),
                                                        m("span.font-weight-bold.pl-4", this.model.options[this.select_medicine].perml + "mg"),
                                                    ]),
                                                    m(".col-12"),
                                                    (this.model.options[this.select_medicine].anotheruseml) ? [
                                                        m(".col-12.col-md-1.pt-md-4", [
                                                            m("span.font-weight-bold", {
                                                                style: {
                                                                    color: "#707070",
                                                                    fontSize: "0.9rem"
                                                                }
                                                            }, "施打劑量(次)"),
                                                        ]),
                                                        m(".col-12.col-md-2.pt-md-4", [
                                                            m("select.form-control.border-0", {
                                                                onchange: (e) => {
                                                                    e.preventDefault()
                                                                    this.useml = e.target.value
                                                                    if (this.price != null && this.select_range != null) {
                                                                        if (this.model.options[this.select_medicine].isWeight && this.weight != null) {
                                                                            this.calculation()
                                                                        } else {
                                                                            this.calculation()
                                                                        }
                                                                    }
                                                                },

                                                            }, [
                                                                m("option", "請選擇施打劑量"),
                                                                [this.model.options[this.select_medicine].useml, this.model.options[this.select_medicine].anotheruseml].map((_value, index) => {
                                                                    return [
                                                                        m("option", {
                                                                            value: _value,
                                                                        }, _value + " mg/kg")
                                                                    ]
                                                                })
                                                            ]),
                                                        ]),
                                                        m(".col-md-12")
                                                    ] : [
                                                            m(".col-12"),
                                                            m(".col-12.col-md-10.pt-2.pt-md-4", [
                                                                m("span.font-weight-bold", {
                                                                    style: {
                                                                        color: "#707070",
                                                                        fontSize: "0.9rem"
                                                                    }
                                                                }, "施打劑量(次)"),
                                                                m("span.font-weight-bold.pl-4", this.model.options[this.select_medicine].useml),
                                                                m("span.font-weight-bold", (this.model.options[this.select_medicine].isWeight) ? "mg/kg" : "mg"),
                                                            ]),
                                                        ]
                                                ],

                                            m(".col-12.col-md-4.pr-md-4.pt-md-3.order-6", [
                                                m(".row.no-gutters.w-100", [
                                                    m(".col-12.pt-3", [
                                                        m("span.font-weight-bold", {
                                                            style: {
                                                                color: "#707070",
                                                                fontSize: "0.9rem"
                                                            }
                                                        }, "療程"),
                                                    ]),
                                                    m(".col-12.pt-1", [
                                                        m("select.form-control.border-0", {
                                                            onchange: (e) => {
                                                                e.preventDefault()
                                                                this.select_range = e.target.value
                                                                if (this.price != null && this.select_range != null) {
                                                                    if (this.model.options[this.select_medicine].isWeight && this.weight != null) {
                                                                        this.calculation()
                                                                    } else {
                                                                        this.calculation()
                                                                    }
                                                                }
                                                            },

                                                        }, [
                                                            m("option", "請選擇療程"),
                                                            this.ranges.map((_range, index) => {
                                                                return [
                                                                    m("option", {
                                                                        value: _range.value,
                                                                    }, _range.name)
                                                                ]
                                                            })
                                                        ]),
                                                    ]),
                                                ])
                                            ]),
                                            (this.model.options[this.select_medicine].isWeight) ? [
                                                m(".col-12.col-md-2.pt-md-3.order-7", [
                                                    m(".row.no-gutters.w-100", [
                                                        m(".col-12.pt-md-3", [
                                                            m("span.font-weight-bold", {
                                                                style: {
                                                                    color: "#707070",
                                                                    fontSize: "0.9rem"
                                                                }
                                                            }, "輸入體重"),
                                                        ]),
                                                        m(".col-12", [
                                                            m("input.form-control.text-center.border-0", {
                                                                placeholder: '輸入體重',
                                                                type: "text",
                                                                value: this.weight,
                                                                type: "number",
                                                                oninput: (e) => {
                                                                    this.weight = e.target.value
                                                                    if (this.price != null && this.select_range != null) {
                                                                        if (this.model.options[this.select_medicine].isWeight && this.weight != null) {
                                                                            this.calculation()
                                                                        } else {
                                                                            this.calculation()
                                                                        }
                                                                    }
                                                                },
                                                            }),
                                                        ])
                                                    ])
                                                ])
                                            ] : "",
                                            m(".col-12.col-md-2.pt-md-3.pl-md-2.order-8", [
                                                m(".row.no-gutters.w-100", [
                                                    m(".col-12.pt-md-3", [
                                                        m("span.font-weight-bold", {
                                                            style: {
                                                                color: "#707070",
                                                                fontSize: "0.9rem"
                                                            }
                                                        }, "輸入藥物單價(瓶)"),
                                                    ]),
                                                    m(".col-12", [
                                                        m("input.form-control.text-center.border-0", {
                                                            placeholder: '輸入藥物單價(瓶)',
                                                            type: "text",
                                                            value: this.price,
                                                            type: "number",
                                                            oninput: (e) => {
                                                                this.price = e.target.value
                                                                if (this.price != null && this.select_range != null) {
                                                                    if (this.model.options[this.select_medicine].isWeight && this.weight != null) {
                                                                        this.calculation()
                                                                    } else {
                                                                        this.calculation()
                                                                    }
                                                                }
                                                            },
                                                        }),
                                                    ])
                                                ])
                                            ]),

                                            m(".col-12.text-center.pt-3.order-9", [
                                                m("span.font-weight-bolder.px-3.pb-1", {
                                                    style: {
                                                        fontSize: "1rem",
                                                        textShadow: "1px 0 #000000",
                                                        borderBottom: "8px #005593 solid"
                                                    }
                                                }, "計算結果"),
                                            ]),
                                            m(".col-12.text-center.order-10", [
                                                m("span.font-weight-bolder.px-3.pb-1", {
                                                    style: {
                                                        fontSize: "3rem",
                                                        textShadow: "1px 0 #000000",
                                                    }
                                                }, this.total.toLocaleString()),
                                            ]),
                                            (this.combile_select)?
                                            []:[
                                            m(".col-12.text-center.order-11", [
                                                m("span.font-weight-bolder", {
                                                    style: {
                                                        fontSize: ".9rem",
                                                    }
                                                }, "提醒您，費用試算後可以再與您的主治醫師詢問藥物是否有相關的病人支持計畫!"),
                                            ]),
                                            ]

                                        ])
                                    ])
                                ]),

                                (this.select_medicine != -1 && this.model.options[this.select_medicine].isCombile) ? [
                                    m(".row.no-gutters.px-4.py-3.w-100", {
                                        style: {
                                            background: "#FFFFFF",
                                            height: (this.combile_select) ? "10vh" : "24.5vh",
                                            // overflow: "auto"
                                        }
                                    }, [
                                        m(".container", [
                                            m(".row.no-gutters.w-100", [
                                                m(".col-1", {
                                                    style: {
                                                        border: "1px " + ((this.combile_select) ? this.model.color : "#000000") + " solid",
                                                        background: (this.combile_select) ? this.model.color : "#FFFFFF",
                                                        minWidth: "25px",
                                                        maxWidth: "25px",
                                                        minHeight: "25px",
                                                        maxHeight: "25px"
                                                    },
                                                    onclick: (e) => {
                                                        this.combile_select = !this.combile_select
                                                    }
                                                }),
                                                m(".col-10.pl-2", {
                                                    onclick: (e) => {
                                                        this.combile_select = !this.combile_select
                                                    }
                                                }, [
                                                    m("span.font-weight-bold", {
                                                        style: {
                                                            color: "#707070",
                                                            fontSize: "1rem"
                                                        }
                                                    }, "可以加上Avastin癌思停"),
                                                    m("span.font-weight-bold", {
                                                        style: {
                                                            color: "#707070",
                                                            fontSize: "1rem"
                                                        }
                                                    }, "每3週一次 / 合併治療"),
                                                ])
                                            ])
                                        ])

                                    ])] : "",
                                (this.combile_select) ? [
                                    m(".px-4.py-3.w-100", {
                                        style: {
                                            height: (window.isMobile) ? "90vh" : "40vh"
                                        }
                                    }, [
                                        m(".container", [
                                            m(".row.no-gutters.w-100", [
                                                m(`.col-12.col-md-2.pt-md-4${(this.model.options[this.select_medicine].changeOrder) ? ".order-3" : ""}`, [
                                                    m("span.font-weight-bold", {
                                                        style: {
                                                            color: "#707070",
                                                            fontSize: "0.9rem"
                                                        }
                                                    }, "每瓶劑量"),
                                                    m("span.font-weight-bold.pl-4", "100mg"),
                                                ]),
                                                m(".col-12"),
                                                m(`.col-12.col-md-1.pt-md-4${(this.model.options[this.select_medicine].changeOrder)?".order-1":""}`, [
                                                    m("span.font-weight-bold", {
                                                        style: {
                                                            color: "#707070",
                                                            fontSize: "0.9rem"
                                                        }
                                                    }, "施打劑量(次)"),
                                                ]),
                                                m(`.col-12.col-md-2.pt-md-4${(this.model.options[this.select_medicine].changeOrder) ? ".order-2" : ""}`, [
                                                    m("select.form-control.border-0", {
                                                        onchange: (e) => {
                                                            e.preventDefault()
                                                            this.useml2 = e.target.value
                                                            if (this.price != null && this.select_range != null) {
                                                                if (this.model.options[this.select_medicine].isWeight && this.weight != null) {
                                                                    this.calculation()
                                                                } else {
                                                                    this.calculation()
                                                                }
                                                            }
                                                        },

                                                    }, [
                                                        m("option", "請選擇施打劑量"),
                                                        [7.5, 15].map((_value, index) => {
                                                            return [
                                                                m("option", {
                                                                    value: _value,
                                                                }, _value + " mg/kg")
                                                            ]
                                                        })
                                                    ]),
                                                ]),
                                                m(".col-12"),
                                                m(".col-12.col-md-4.pr-md-4.pt-md-3", [
                                                    m(".row.no-gutters.w-100", [
                                                        m(".col-12.pt-3", [
                                                            m("span.font-weight-bold", {
                                                                style: {
                                                                    color: "#707070",
                                                                    fontSize: "0.9rem"
                                                                }
                                                            }, "療程"),
                                                        ]),
                                                        m(".col-12.pt-1", [
                                                            m("select.form-control.border-0", {
                                                                onchange: (e) => {
                                                                    e.preventDefault()
                                                                    this.select_range2 = e.target.value
                                                                    if (this.price2 != null && this.weight2 != null && this.select_range2 != null) {
                                                                        if (this.select_range2 == 1) {
                                                                            this.times2 = 1
                                                                        } else {
                                                                            this.times2 = Math.floor(this.select_range2 / 3)
                                                                        }
                                                                        this.total2 = Math.ceil(this.weight2 * Math.ceil(this.useml2) * this.times2 / 100) * this.price2
                                                                    }
                                                                },

                                                            }, [
                                                                m("option", "請選擇療程"),
                                                                this.ranges.map((_range, index) => {
                                                                    return [
                                                                        m("option", {
                                                                            value: _range.value,
                                                                        }, _range.name)
                                                                    ]
                                                                })
                                                            ]),
                                                        ]),
                                                    ])
                                                ]),
                                                m(".col-12.col-md-2.pt-md-3", [

                                                    m(".row.no-gutters.w-100", [
                                                        m(".col-12.pt-md-3", [
                                                            m("span.font-weight-bold", {
                                                                style: {
                                                                    color: "#707070",
                                                                    fontSize: "0.9rem"
                                                                }
                                                            }, "輸入體重"),
                                                        ]),
                                                        m(".col-12.pt-md-1", [
                                                            m("input.form-control.text-center.border-0", {
                                                                placeholder: '輸入體重',
                                                                type: "text",
                                                                value: this.weight2,
                                                                type: "number",
                                                                oninput: (e) => {
                                                                    this.weight2 = e.target.value
                                                                    if (this.price2 != null && this.weight2 != null && this.select_range2 != null) {
                                                                        if (this.select_range2 == 1) {
                                                                            this.times2 = 1
                                                                        } else {
                                                                            this.times2 = Math.floor(this.select_range2 / 3)
                                                                        }
                                                                        this.total2 = Math.ceil(this.weight2 * 7.5 * this.times2 / 100) * this.price2
                                                                    }
                                                                },
                                                            }),
                                                        ])
                                                    ]),
                                                ]),
                                                m(".col-12.col-md-2.pt-md-3", [
                                                    m(".row.no-gutters.w-100", [
                                                        m(".col-12.pt-md-3", [
                                                            m("span.font-weight-bold", {
                                                                style: {
                                                                    color: "#707070",
                                                                    fontSize: "0.9rem"
                                                                }
                                                            }, "輸入藥物單價(瓶)"),
                                                        ]),
                                                        m(`.col-12.pl-2.pt-1`, [
                                                            m("input.form-control.text-center.border-0", {
                                                                placeholder: '輸入藥物單價(瓶)',
                                                                type: "text",
                                                                value: this.price2,
                                                                type: "number",
                                                                oninput: (e) => {
                                                                    this.price2 = e.target.value
                                                                    if (this.price2 != null && this.weight2 != null && this.select_range2 != null) {
                                                                        if (this.select_range2 == 1) {
                                                                            this.times2 = 1
                                                                        } else {
                                                                            this.times2 = Math.floor(this.select_range2 / 3)
                                                                        }
                                                                        this.total2 = Math.ceil(this.weight2 * 7.5 * this.times2 / 100) * this.price2
                                                                    }
                                                                },
                                                            }),
                                                        ]),

                                                    ])
                                                ]),


                                                m(".col-12.text-center.pt-3", [
                                                    m("span.font-weight-bolder.px-3.pb-1", {
                                                        style: {
                                                            fontSize: "1rem",
                                                            textShadow: "1px 0 #000000",
                                                            borderBottom: "8px #89B1CE solid"
                                                        }
                                                    }, "合併計算"),
                                                ]),
                                                m(".col-12.text-center", [
                                                    m("span.font-weight-bolder.px-3.pb-1", {
                                                        style: {
                                                            fontSize: "3rem",
                                                            textShadow: "1px 0 #000000",
                                                        }
                                                    }, (this.total2 + this.total).toLocaleString()),
                                                ]),

                                                (this.combile_select) ?
                                                     [
                                                        m(".col-12.text-center.order-11", [
                                                            m("span.font-weight-bolder", {
                                                                style: {
                                                                    fontSize: ".9rem",
                                                                }
                                                            }, "提醒您，費用試算後可以再與您的主治醫師詢問藥物是否有相關的病人支持計畫!"),
                                                        ]),
                                                    ]:""

                                            ])
                                        ])
                                    ]),
                                ] : ""
                            ])
                        ] : "",


                    ])
                ]),
                m(".row.no-gutters.w-100.position-fixed", {
                    style: {
                        bottom: 0,
                    }
                }, [
                    m(".col-12.d-flex.align-items-end.justify-content-center", {
                        style: {
                            height: "14.5vh",
                            maxHeight: "14.5vh",
                            background: "#FFFFFF",
                            boxShadow: "0px -2px 8px #00000029"
                        }
                    }, [
                        m("img.img-fluid", {
                            src: "./img/logo.png",
                            style: {
                                height: "14.5vh",
                                maxHeight: "14.5vh",
                            }
                        }),
                    ])
                ]),
            ])


        ])
    }
}

export default EventDialog